'use client'

import {
  RiErrorWarningLine,
  RiLogoutBoxRLine,
  RiSparklingLine,
} from '@remixicon/react'
import { Button } from 'antd'
import localFont from 'next/font/local'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import { logoIndexClx } from '@/branding-config'
import { API_URL, APP_URL, COMPANY } from '@/constants/env'
import { cn } from '@/utils/clsx'

import companyLogo from '../../../public/companyLogo.png'

const pinnacleFont = localFont({ src: '../../../public/Saans-Regular-1.woff2' })

const Header: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { settings } = useDbSettings()
  const { startLoading } = useLoadingState()

  return (
    <section className='flex flex-col justify-center p-2 text-on-background sm:p-4 lg:h-28 xl:px-20 dark:text-dark-on-background'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-6 md:gap-14'>
          <Link href='/' className='flex items-center gap-2 sm:gap-6'>
            <Image
              src={companyLogo}
              alt='company logo'
              className={cn('cursor-pointer', logoIndexClx)}
            />
            <div className='flex flex-col text-left'>
              <span
                className={cn(
                  'text-xl font-bold text-on-surface dark:text-dark-on-surface',
                  pinnacleFont.className
                )}
              >
                {COMPANY}
              </span>
              <div className='hidden gap-1 text-sm text-on-surface/60 md:block dark:text-dark-on-surface/60'>
                {t('index-header')}
              </div>
            </div>
          </Link>
          <div className='hidden gap-8 lg:flex'>
            <Link
              href='#features'
              type='text'
              className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
            >
              Features
            </Link>
            <Link
              href='#testimonials'
              type='text'
              className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
            >
              Testimonials
            </Link>
            <Link
              href='#pricing'
              type='text'
              className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
            >
              Pricing
            </Link>
          </div>
        </div>
        {settings?.requireSubscription &&
        user &&
        !user.subscribed &&
        user.role !== 'ADMIN' ? (
          <div className='flex gap-2'>
            <Link href='/signup'>
              <Button
                type='primary'
                icon={<RiErrorWarningLine className='size-5' />}
              >
                <span className='!hidden sm:!flex'>Complete Sign Up</span>
              </Button>
            </Link>
            <Link
              href={`${API_URL}/auth/logout`}
              onClick={() => startLoading()}
            >
              <Button icon={<RiLogoutBoxRLine className='size-4' />}>
                <span className='!hidden sm:!flex'>{t('logout')}</span>
              </Button>
            </Link>
          </div>
        ) : user ? (
          <Link href={APP_URL ?? ''}>
            <Button
              type='primary'
              icon={<RiSparklingLine className='size-5' />}
            >
              Launch App
            </Button>
          </Link>
        ) : (
          <div className='flex gap-1 sm:gap-2'>
            <Link href={`${APP_URL}/login`}>
              <Button>{t('sign-in')}</Button>
            </Link>
            <Link href={`${APP_URL}/signup`}>
              <Button type='primary'>{t('sign-up')}</Button>
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}

export default Header
